<template>
  <ion-page>
    <Header
      @showSearch="(v) => showSearch = v"
    />

    <ion-content v-if="isLoading" >
      <div class="is-flex ion-align-items-center ion-justify-content-center h-100">
        <IonSpinner/>
      </div>
    </ion-content>

    <ion-content
      v-else-if="!showSearch"
      class="ion-padding-start ion-padding-end pt-5"
    >
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh($event)"
      >
        <ion-refresher-content/>
      </ion-refresher>

      <ion-grid>
        <ion-row>
          <ion-col
            size="12"
            size-md="3"
          >
            <!-- Following Companies -->
            <h3 class="title">{{ $t('following.followingOrganisations') }}</h3>

            <template
              v-if="followingData.length"
            >
              <ion-row v-if="isDesktopScreen">
                <ion-col
                  size="6"
                  size-md="12"
                  v-for="(data, i) in followingData"
                  :key="`data-${i}`"
                  class="mt-4"
                >
                  <CompanyDetails
                    :company-details="data.company"
                    only-name
                  />
                </ion-col>
              </ion-row>


              <swiper
                v-else
                :slides-per-view="'auto'"
                :space-between="30"
                :modules="modules"
                :grid="{ rows: 2, fill: 'row' }"
              >
                <swiper-slide
                  v-for="(data, i) in followingData"
                  :key="`item-${i}`"
                >
                  <CompanyDetails
                    :company-details="data.company"
                    only-name
                    class="company-details"
                  />
                </swiper-slide>
              </swiper>
            </template>
            <h4
              v-else
              class="no-data-title"
            >{{ $t('following.noFollowing') }}</h4>
          </ion-col>


          <ion-col
            size="12"
            size-md="9"
          >
            <!-- Following Series -->
            <h3 class="title mt-sm-30">{{ $t('following.followingSeries') }}</h3>

            <swiper
              v-if="favouriteSeries.length"
              :slides-per-view="'auto'"
              :space-between="30"
              :modules="modules"
              :grid="{ rows: isDesktopScreen ? 1 : 2, fill: 'row' }"
            >
              <swiper-slide
                v-for="(series, i) in favouriteSeries"
                :key="`item-${i}`"
              >
                <FavouriteSeries
                  :item="series"
                  @click="$router.push(`/course/${series.id}`)"
                  :mobile-small=true
                />
              </swiper-slide>
            </swiper>

            <h4
              v-else
              class="no-data-title"
            >{{ $t('following.noSeries') }}</h4>

            <!-- Following Episodes -->
            <div class="mt-10">
              <h3 class="title mt-sm-30">{{ $t('following.followingEpisods') }}</h3>

              <swiper
                v-if="favouriteEpisodes.length"
                :slides-per-view="'auto'"
                :space-between="30"
                :modules="modules"
                :grid="{ rows: isDesktopScreen ? 1 : 2, fill: 'row' }"
              >
                <swiper-slide
                  v-for="(episode, i) in favouriteEpisodes"
                  :key="`item-${i}`"
                >
                  <FavouriteEpisode
                    :item="episode"
                    @click="openPlayer(episode.streams[0], episode.streams)"
                  />
                </swiper-slide>
              </swiper>

              <h4
                v-else
                class="no-data-title"
              >{{ $t('following.noEpisodes') }}</h4>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import useDeviceScreen from '@/composables/useDeviceScreen';
import CompanyDetails from '@/components/common/CompanyDetails.vue';
import FavouriteSeries from '@/components/following/FavouriteSeries.vue';
import FavouriteEpisode from '@/components/following/FavouriteEpisode.vue';
import Header from '@/components/common/Header';
import { Swiper, SwiperSlide } from 'swiper/swiper-vue';
import { Grid } from 'swiper/modules';
import 'swiper/swiper.css';
import 'swiper/modules/grid.css';

import {
  IonContent,
  IonPage,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

const store = useStore();
const modules= [Grid];
const { isDesktopScreen } = useDeviceScreen();

const isLoading = ref(true);
const showSearch = ref(false);

const refresh = async (ev) => {
  await Promise.all([
    store.dispatch('favorites/setFavoritesList', false),
    store.dispatch('favorites/setFollowingList')]);
  ev.target.complete();
};

const followingData = computed(() => store.getters['favorites/getFollowingList']);
const favoritesList = computed(() => store.getters['favorites/getRawFavoritesList']);

const favouriteSeries = computed(() => favoritesList.value.filter((item) => item.entity_type === 'series'));
const favouriteEpisodes = computed(() => favoritesList.value.filter((item) => item.entity_type === 'episode'));

const openPlayer = async (stream) => {
  const course = getCourse(stream);
  const startAt = course.streams.findIndex(({ id }) => id === stream.id);

  store.dispatch('course/setCourseData', course.id);
  store.dispatch('player/openPlayer', {
    company: course.company,
    course,
    playlist: course.streams,
    startAt,
  });
};

const getCourse = (stream) => {
  const rawFavoritesList = store.state.favorites.rawFavoritesList ?? [];
  return rawFavoritesList.find((course) => course.id === stream.course_id) ?? {};
};

const fetchFavorites = () => {
  isLoading.value = true;
  store.dispatch('favorites/setFavoritesList').finally(() => {
    isLoading.value = false;
  });
};

watch(
  () => store.state.favorites.favoritesUpdated,
  (newValue) => {
    if (newValue) {
      fetchFavorites(newValue);
      store.commit('favorites/setFavoritesUpdated', false);
    }
  },
);

Promise.all([
  store.dispatch('favorites/setFavoritesList'),
  store.dispatch('favorites/setFollowingList')]).then(() => isLoading.value = false );
</script>

<style lang="scss" scoped>
 @import "src/theme/custom";

 .title {
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0;
}

.mt-sm-30 {
  @media screen and (max-width: $tablet-breakpoint) {
    margin-top: 30px;
  }
}

.no-data-title {
  font-size: 16px;
  font-weight: 400;
  color: #D6D6D6;
}

.swiper {
  width: 100%;

  .swiper-slide {
    cursor: pointer;
    width: auto;
  }
}

.company-details {
  @media screen and (max-width: $tablet-breakpoint) {
    width: 200px;

    :deep(.company-name) {
      text-align: left;
    }
  }
}
</style>
